.AboutUs {
    padding-bottom: 20px;
}

.AboutUs .info-para {
    line-height: 1.6;
}

.AboutUs .rating {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.AboutUs .rating-text {
    margin-top: 10px;
    width: 50%;
    padding: 5px 10px;
}

.AboutUs button {
    width: fit-content;
}

.AboutUs button:hover {
    opacity: 0.7;
}

.AboutUs .p-ratings {
    padding-top: 20px;
}

.AboutUs .p-rating-div {
    width: 40%;
    padding: 10px 0px;
}

.AboutUs .text {
    margin-left: 55px;
    margin-top: 10px;
    border: 1px solid rgb(186, 186, 186);
    padding: 5px 10px;
}

.AboutUs .profile-row {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 5px;
}

.AboutUs .profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid grey;
}

.AboutUs .rate {
    padding-left: 55px;
}

@media only screen and (max-width: 768px) {
    .AboutUs .rating-text {
        width: 90%;
    }

    .AboutUs .p-rating-div {
        width: 100%;
    }

    .AboutUs .text {
        margin-right: 5px;
    }
}