.h-screen {
    height: 100vh;
}

.h-100 {
    height: 100%;
}

.h-75 {
    height: 75%;
}

.h-50 {
    height: 50%;
}

.h-25 {
    height: 25%;
}

.w-screen {
    width: 100vw;
}

.w-75 {
    width: 75%;
}

.w-50 {
    width: 50%;
}

.w-25 {
    width: 25%;
}

.w-400 {
    width: 400px;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.items-center {
    align-items: center;
}

.items-start {
    align-items: flex-start;
}

.items-end {
    align-items: flex-end;
}

.gap-1 {
    gap: 10px;
}

.gap-2 {
    gap: 20px;
}

.gap-3 {
    gap: 30px;
}

.gap-4 {
    gap: 40px;
}

.gap-5 {
    gap: 50px;
}

.p-5 {
    padding: 50px;
}

.p-4 {
    padding: 40px;
}

.p-3 {
    padding: 30px;
}

.p-2 {
    padding: 20px;
}

.p-1 {
    padding: 10px;
}

.mb-2 {
    margin-bottom: 10px;
}