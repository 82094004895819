/* Modal Overlay */
.waiting-modal-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal Content */
.waiting-modal-content {
    background: #fff;
    border-radius: 12px;
    padding: 24px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    overflow: none;
    border: none;
}

/* Modal Header */
.waiting-modal-header h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #253864;
    margin-bottom: 16px;
}

/* Timer Section */
.waiting-modal-timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0;
}

.waiting-modal-timer svg {
    margin-bottom: 12px;
    animation: spin 2s linear infinite; /* Add spinning animation */
}

/* Spinning animation */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Footer Text */
.waiting-modal-footer {
    font-size: 0.875rem;
    color: #6b7280;
    margin-top: 16px;
}
