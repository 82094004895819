.login-logo {
    width: 80px;
    height: 90px;
}

.card {
    width: 400px;
}

@media only screen and (max-width: 545px) {
    .card {
        width: 70vw;
    }
}