.Ranking {
    padding-bottom: 40px;
}

.Ranking .leaderboard {
    border: 4px solid #6cb1fb;
    border-radius: 10px;
    padding: 50px 20px 20px 70px;
    background-color: #162858eb;
    color: white;
}

.Ranking .legend {
    background-color: #162858eb;
    border: 4px solid #5168f1;
    padding: 8px 20px;
    border-radius: 15px;
    font-size: 30px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.Ranking .legend .trophy {
    padding-right: 20px;
    color: #FFD700;
}

.Ranking .data {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Ranking .row {
    display: flex;
    align-items: center;
    gap: 20px;
}

.Ranking .position {
    width: 100px;
}

.Ranking .medal {
    font-size: 40px;
    text-align: center;
}

.Ranking .number {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}

.Ranking .profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.Ranking .profile-icon {
    width: 50px;
    height: 50px;
}

.Ranking .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(90deg, #3264e9, #2349b1);
    padding: 8px 12px;
    border-radius: 8px;
    width: 100%;
}

.Ranking .name {
    width: 30%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Ranking .school {
    width: 25%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Ranking .class {
    width: 25%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}

@media only screen and (max-width: 768px) {
    .Ranking {
        width: 75.5vw;
        overflow-x: scroll;
    }

    .Ranking .leaderboard {
        padding: 30px 6px;
    }

    .Ranking .legend {
        font-size: 12px;
        padding: 8px 4px;
    }

    .Ranking .legend .trophy {
        padding-right: 5px;
    }

    .Ranking .row {
        gap: 5px;
    }

    .Ranking .position {
        width: 40px;
    }

    .Ranking .medal {
        font-size: 22px;
    }

    .Ranking .number {
        font-size: 14px;
    }

    .Ranking .profile {
        width: 25px;
        height: 25px;
        border-radius: 50%;
    }

    .Ranking .profile-icon {
        width: 25px;
        height: 25px;
    }

    .Ranking .flex {
        padding: 8px 4px;
    }

    .Ranking .name,
    .Ranking .school,
    .Ranking .class {
        font-size: 9px;
    }

    .Ranking .score {
        font-size: 10px;
    }

}