@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

* {
  font-family: "Montserrat", sans-serif !important;
}

body{
  padding: 0;
  margin: 0;
}
