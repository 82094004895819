input {
  height: 20px;
  padding: 10px 20px;
  border: 2px solid #ccc;
  width: 89%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ant-form-item {
  margin-bottom: 5px !important;
}

button {
  padding: 0px 25px;
  border-radius: 2px;
  height: 40px !important;
  cursor: pointer;
}

.primary-contained-btn {
  background-color: var(--primary);
  color: white;
}

.mobile-btn {
  padding: 0px 10px;
  font-size: small;
}

.primary-outlined-btn {
  background-color: white;
  color: var(--primary);
  border: 2px solid var(--primary);
}

select {
  height: 42px;
  padding: 10px 20px;
  border: 2px solid #ccc;
  width: 100%;
  appearance: none;
  background-color: white;
  color: #000;
  font-size: 14px;
  cursor: pointer;
}
