.card {
    width: 400px;
}

.main {
    min-height: 100vh;
    padding: 20px 0px;
}

@media only screen and (max-width: 545px) {
    .card {
        width: 70vw;
    }

    .main {
        height: 100%;
    }
}