.layout {
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.sidebar {
  background-color: var(--primary);
  padding: 20px 10px;
  border-radius: 5px;
  height: 100vh;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  overflow-y: auto;
  box-sizing: border-box;
  scrollbar-width: thin;
}

.mobile-sidebar {
  width: 15vw;
  padding: 5px;
}

.menu {
  height: fit-content;
}

.mobile-sidebar .menu-item {
  padding: 10px 5px;
  margin-bottom: 15px;
  justify-content: center;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 17px 20px;
  margin: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  gap: 15px;
  color: white !important;
}

.active-menu-item {
  border: 2px solid white;
  border-radius: 5px;
}

.body {
  overflow: hidden;
  flex: 1;
}

.collapsed-body {
  /* margin-left: 102px; */
}

.mobile-collapsed-body {
  /* margin-left: 20vw; */
}

.no-collapse-body {
  /* margin-left: 230px; */
}

.content {
  overflow-y: auto;
}

.header {
  background-color: var(--primary);
  color: white !important;
  padding: 15px;
  border-radius: 5px;
  align-items: center !important;
  box-sizing: border-box;
  width: 100%;
}