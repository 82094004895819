.card {
  box-shadow: 0 0 1px gray;
}

.card-lg {
  box-shadow: 0 0 2px rgb(128, 128, 128);
}

.divider {
  border-bottom: 1px solid rgb(182, 182, 182);
  margin: 10px 0;
}

.loader-parent {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.638);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader {
  height: 80px;
  width: 80px;
  border: 5px solid #f3f3f3;
  border-left-color: transparent;
  border-radius: 50%;
  animation: loader 1s linear infinite;
}

.option {
  box-shadow: rgba(0, 0, 0, 0.638) 0px 0px 1.5px;
  padding: 10px;
  border-radius: 2px;
}

.selected-option {
  box-shadow: rgba(0, 0, 0, 0.638) 0px 0px 1px;
  padding: 10px;
  border-radius: 2px;
  border: 2px solid var(--primary);
}

.result {
  background-color: #a5c8c9;
  max-width: max-content;
  padding: 20px;
  color: black !important;
  border-radius: 5px;
}

.lottie-animation {
  height: 300px;
}

.timer {
  background-color: #894114;
  color: white !important;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}