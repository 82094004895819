
/* Modal Overlay */
.modal-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal Content */
.modal-content {
    background: #fff;
    border-radius: 12px;
    padding: 24px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
}

/* Header Icon */
.modal-header {
    margin-bottom: 16px;
}

.success-icon {
    font-size: 48px;
    color: #10b981;
    display: inline-block;
    margin-bottom: 8px;
}

/* Title and Subtitle */
.modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #0f3460;
    margin-bottom: 8px;
}

.modal-subtitle {
    font-size: 0.875rem;
    color: #6b7280;
    cursor: pointer;
    text-decoration: underline;
}

/* Details Section */
.modal-details {
    background: #f9fafb;
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0;
    text-align: left;
}

.detail-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.detail-label {
    font-size: 0.875rem;
    color: #6b7280;
    font-weight: bold;
}

.detail-value {
    font-size: 0.875rem;
    color: #1f2937;
}

/* Button */
.modal-button {
    background: #10b981;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 12px 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.modal-button:hover {
    background: #059669;
}
