.study-material {
    height: fit-content;
    padding-bottom: 40px;
}

.study-material .flex {
    flex-wrap: wrap;
}

.study-material .content {
    margin-top: 10px;

}

.study-material .content select {
    /* margin-left: 10px; */
    width:80% ;
    display: block;
}

.study-material .class {
    margin-top: 10px;
}

.study-material .class select {
    /* margin-left: 10px; */
    width:80% ;
    display: block;

}

.study-material .subject {
    margin-top: 10px;
}

.study-material .subject select {
    /* margin-left: 10px; */
    width:80% ;
display: block;
}

.study-material .common {
    margin-top: 40px;
}

.study-material .common .title {
    margin-bottom: 7px;
}

.study-material .common .year {
    margin-bottom: 7px;
}

.study-material .common .btn {
    border: 2px solid rgb(58, 58, 58);
    border-radius: 5px;
    background: linear-gradient(to left, white 50%, black 50%) right;
    background-size: 200% 100%;
    transition: .3s ease-out;
    padding: 2px 15px;
}

.study-material .common .btn:hover {
    background-position: left;
    color: white;
    cursor: pointer;
}

.study-material .not-found {
    margin-top: 20px;
    font-weight: 700;
}

.study-material .books .thumbnail-container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.study-material .books .thumbnail-container .thumbnail {
    width: 250px;
    height: 250px;
    border-radius: 8px;
    margin-bottom: 12px;
    transition: transform 0.3s ease;
}

.study-material .books .thumbnail-container .thumbnail:hover {
    cursor: pointer;
    transform: scale(1.05);
}


.button-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
  }
  
.button-container button {
    width: 200px; 
    margin-bottom: 10px;
  }
  